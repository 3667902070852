import http from '../index';

const BASEURI = 'patient/municipio';

const MunicipioApi = {
  // eslint-disable-next-line consistent-return
  getMunicipio: async () => {
    try {
      const request = await http.get(BASEURI);
      const { data } = request;
      if (data) {
        return data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  postMunicipio: async (data) => {
    try {
      const request = await http.post(BASEURI, data);
      if (request) {
        this.$notify({
          type: 'success',
          message: 'Municipio agregado correctamente!',
        });
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
};

export default MunicipioApi;
